import Cookies from "js-cookie";
import { stringToBoolean } from "../../helpers/helpers";

export function sidebarCollapse() {
  const $sbButton = $("#btn-sidebar-toggle");
  const $sbContainer = $("#mp-menu");
  const $mainContent = $(".content-inner-main");

  const strIsCollapsed =
    Cookies.get("sidebarCollapsed") !== "true"
      ? "false"
      : Cookies.get("sidebarCollapsed");
  var isCollapsed = stringToBoolean(strIsCollapsed);

  //Setup event to trigger re-rendering if needed
  const toggleEvent = new CustomEvent("sidebarToggled");

  //Handle saved session state on init
  if (isCollapsed === true) {
    $sbContainer.addClass("-collapsed").hide();
    $mainContent.addClass("-no-sidebar");
    $sbButton.addClass("-collapsed").attr("aria-pressed", "false");
  }

  //Add a keyboard nav skip to get to this section
  if ($sbButton.length > 0) {
    $("#mp-menu").before('<a id="side-nav-bookmark"></a>');
    $(".skip")
      .last()
      .after(
        '<a href="#side-nav-bookmark" class="skip">Skip to customisable sidebar</a>'
      );
  }

  $sbButton.on("click", handleSidebarClick);

  function handleSidebarClick(ev) {
    //Collapse/expanded sidebar and main section in order
    if (isCollapsed) {
      $mainContent.toggleClass("-no-sidebar");
      setTimeout(() => {
        $sbButton.toggleClass("-collapsed");
        $sbContainer.slideToggle(200);
      }, 200);
    } else {
      $sbButton.toggleClass("-collapsed");
      $sbContainer.slideToggle(200);
      setTimeout(() => {
        $mainContent.toggleClass("-no-sidebar");
      }, 200);
    }

    setTimeout(() => {
      window.dispatchEvent(toggleEvent);
    }, 400);

    isCollapsed = !isCollapsed;
    Cookies.set("sidebarCollapsed", isCollapsed);
  }
}
